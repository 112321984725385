import { createSlice } from "@reduxjs/toolkit";
import { constants } from "src/constant/constants";

const getUserData = () => {
  try {
    const userData = localStorage.getItem(constants.userData);
    if (userData === null) {
      return {};
    }
    return JSON.parse(userData);
  } catch (error) {
    return {};
  }
}

const getUserRole = () => {
  try {
    const userRole = localStorage.getItem(constants.userRole);
    if (userRole === null) {
      return "";
    }
    return JSON.parse(userRole);
  } catch (error) {
    return "";
  }
}
const initialState = {
  isLoggedIn: !!localStorage.getItem(constants.accessToken),
  isAdmin: getUserRole() === "admin",
  userData: getUserData()
}
const authSlice = createSlice({
  name: "auth",
  // initialState: { isLoggedIn: false, isAdmin: false },
  initialState,
  reducers: {
    adminLogin: (state) => {
      return {
        ...state,
        isLoggedIn: true,
        isAdmin: true,
      };
    },
    ownerLogin: (state, action) => {
      return {
        ...state,
        isLoggedIn: true,
        isAdmin: false,
        userData: action.payload
      };
    },
    logoutUser: (state, action) => {
      return {
        ...state,
        isLoggedIn: false,
        userData: {}
      };
    },
  },
});
export const { adminLogin, ownerLogin, logoutUser } =
  authSlice.actions;

export default authSlice.reducer;
