import { lazy } from "react";
import { Navigate } from "react-router-dom";
const OwnerSearchHorse = lazy(() => import("src/views/horse-list/OwnerSearchHorse"));
// const Dashboard = lazy(() => import("src/views/dashboard/Dashboard"));
const OwnerLogin = lazy(() => import("src/views/pages/login/OwnerLogin"));
const HorseList = lazy(() => import("src/views/horse-list/HorseList"));
const OwnerProfile = lazy(() =>
  import("src/views/pages/owner-profile/OwnerProfile")
);

export const ownerRoutes = [
  {
    path: "/login",
    name: "Login",
    element: <OwnerLogin />,
    authPage: true,
  },
  {
    path: "/horse-list",
    name: "Horse List",
    element: <HorseList />,
    privatePage: true,
  },
  {
    path: "/profile",
    name: "Profile",
    element: <OwnerProfile />,
    privatePage: true,
  },
  {
    path: "/search-all-horses",
    name: "Search Horses",
    element: <OwnerSearchHorse />,
    privatePage: true,
  },
].map((data) => ({ ...data, ownerRoute: true }));
