import { constants } from "src/constant/constants";
import { deleteRequest, getRequest, postRequest, putRequest } from "../helper";

export const getAdminHorseRequestList = async (filterParams) => {
  try {
    const res = await getRequest(
      constants.endPoints.adminEndPoints.horseRequestsList,
      filterParams
    );
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const getOwnerHorseRequestList = async (filterParams) => {
  try {
    const res = await getRequest(
      constants.endPoints.ownerEndPoints.horseRequestsList,
      filterParams
    );
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const getStats = async () => {
  try {
    const res = await getRequest(constants.endPoints.adminEndPoints.statistics);
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const changeOwner = async (payload) => {
  try {
    const res = await postRequest(
      constants.endPoints.adminEndPoints.changeOwner,
      payload
    );
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const editOwner = async (ownerId, payload) => {
  try {
    const res = await putRequest(
      `${constants.endPoints.adminEndPoints.editOwner}/${ownerId}`,
      payload
    );
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const deleteOwner = async (ownerId) => {
  try {
    const res = await deleteRequest(
      `${constants.endPoints.adminEndPoints.deleteOwner}/${ownerId}`
    );
    return res.data;
  } catch (e) {
    // console.log(e);
    throw e;
  }
};

export const editHorse = async (horseId, payload) => {
  try {
    const res = await putRequest(
      `${constants.endPoints.adminEndPoints.adminEditHorse}/${horseId}`,
      payload
    );
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const deleteHorse = async (horseId) => {
  try {
    const res = await deleteRequest(
      `${constants.endPoints.adminEndPoints.adminDeleteHorse}/${horseId}`
    );
    return res.data;
  } catch (e) {
    // console.log(e);
    throw e;
  }
};

export const emailWithCredential = async (id) => {
  try {
    const res = await postRequest(
      `${constants.endPoints.adminEndPoints.sendCredential}/${id}`
    );
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const sendRequestOtpAdmin = async (payload) => {
  try {
    const res = await postRequest(
      `${constants.endPoints.adminEndPoints.adminOtpPassword}`,
      payload
    );
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const sendOtpForAdminPass = async (payload) => {
  try {
    const res = await postRequest(
      `${constants.endPoints.adminEndPoints.adminSendOtp}`,
      payload
    );
    return res.data;
  } catch (e) {
    throw e;
  }
};


export const changePasswordForAdmin = async (payload) => {
  try {
    const res = await putRequest(
      `${constants.endPoints.adminEndPoints.adminChangePassword}`,
      payload
    );
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const changeEmailForAdmin = async (payload) => {
  try {
    const res = await postRequest(
      `${constants.endPoints.adminEndPoints.adminEmailOtpSend}`,
      payload
    );
    return res.data;
  } catch (e) {
    throw e;
  }
};

export const ChangeEmailForAdminSave = async (payload) => {
  try {
    const res = await putRequest(
      `${constants.endPoints.adminEndPoints.adminChangeEmail}`,
      payload
    );
    return res.data;
  } catch (e) {
    throw e;
  }
};


